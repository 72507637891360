/**
 * Note: around @chakra-ui/react 1.6.4 Chakra had `vertical-align: middle` for all of these elements:
 * img, svg, video, canvas, audio, iframe, embed, object
 * After upgrading to 1.7.1 the alignment was removed which broke most of the alignment of icons in the app.
 * Specifically, the broken vertical alignment was seen in layouts that use `display: inline-block`. Layouts that used
 * flex with justify-content/align-items was unaffected by that change.
 *
 * Most other design systems and/or UI libraries use CSS normalizers that use `vertical-align: middle` so it's wise
 * to add it ourselves here.
 */
img,
svg {
    vertical-align: middle;
}

#chakra-toast-manager-top {
    top: 100px !important;
}

#chakra-toast-portal .chakra-toast__inner {
    max-width: 800px !important;
}

/* CSS specific to iOS devices */
@supports (-webkit-touch-callout: none) {
    /**
     * iOS browsers have a bug with chakra modals which stretch the modals all the way to fill the screen.
     * The problem is that the URL bar and other browser controls sometimes overlap the content in the viewport.
     */
    .chakra-modal__header {
        margin-top: 32px;
    }
    .chakra-modal__footer {
        margin-bottom: 32px;
    }

    .chakra-modal__close-btn {
        top: 44px !important;
    }
}

/* React Datepicker */
.react-datepicker-popper {
    z-index: var(--chakra-zIndices-popover);
}

.react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 8px;
}

.react-datepicker {
    border: none;
    font-family: Poppins;
    border-radius: var(--chakra-radii-md);
}

.react-datepicker__header {
    padding-top: 10px;
    background-color: var(--chakra-colors-background-quaternary);
    border-bottom: 1px solid var(--chakra-colors-datepicker-header-border);
}

.react-datepicker__month-container {
    width: 254px;
    box-shadow: 0px 4px 10px rgba(26, 26, 26, 0.2);
    border-radius: var(--chakra-radii-md);
    background-color: var(--chakra-colors-background-primary);
    border: 1px solid var(--chakra-colors-datepicker-border);
}

.react-datepicker__current-month {
    width: 60px;
    font-size: 16px;
    color: var(--chakra-colors-datepicker-month-selected);
}

.react-datepicker__navigation--previous:disabled,
.react-datepicker__navigation--next:disabled,
.react-datepicker__triangle {
    display: none;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    top: 15px;
}

.react-datepicker__navigation--previous {
    left: 10px;
}

.react-datepicker__navigation-icon--previous {
    right: 1px;
}

.react-datepicker__navigation--next {
    right: 10px;
}

.react-datepicker__navigation-icon--next {
    left: 1px;
}

.react-datepicker__navigation-icon {
    top: 5px;
}

.react-datepicker__day {
    color: var(--chakra-colors-datepicker-text);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    background: var(--chakra-colors-item-hover);
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--keyboard-selected {
    color: var(--chakra-colors-white) !important;
    background: var(--chakra-colors-blue-400) !important;
}

.react-datepicker__day-names {
    margin-top: 8px;
}

.react-datepicker__day-name {
    color: var(--chakra-colors-datepicker-text);
    text-transform: uppercase;
    font-size: 11px;
}

.react-datepicker__input-container input[type='text'] {
    background: var(--chakra-colors-background-tertiary);
    border: 1px solid var(--chakra-colors-datepicker-input-border);
    color: var(--chakra-colors-input-text);
}

.react-datepicker__input-container svg {
    color: var(--chakra-colors-gray-600);
}

.react-datepicker__input-container input:disabled {
    border: 1px solid var(--chakra-colors-input-disabled-border) !important;
}

.react-datepicker__input-container input:disabled,
.react-datepicker__input-container input:disabled + div {
    cursor: not-allowed;
    background: var(--chakra-colors-background-primary) !important;
    border-top: 1px solid var(--chakra-colors-input-disabled-border);
    border-right: 1px solid var(--chakra-colors-input-disabled-border);
    border-bottom: 1px solid var(--chakra-colors-input-disabled-border);
    border-radius: var(--chakra-radii-md);
}

.react-datepicker__input-container input:focus + div svg {
    color: var(--chakra-colors-blue-400);
}

.react-datepicker__input-time-container {
    margin: -3px 0;
    background: var(--chakra-colors-background-tertiary);
    padding: 10px 15px;
    border-bottom-right-radius: var(--chakra-radii-md);
    border-bottom-left-radius: var(--chakra-radii-md);
    box-shadow: 0px 4px 10px rgba(26, 26, 26, 0.2);
    border: 1px solid var(--chakra-colors-datepicker-border);
}

.react-datepicker-time__input {
    background: var(--chakra-colors-background-tertiary);
    color: var(--chakra-colors-input-text);
}

.react-datepicker-time__caption {
    font-weight: bold;
    color: var(--chakra-colors-item-text);
}

.react-datepicker__close-icon {
    right: 30px;
}

[data-disabled] + .react-datepicker-wrapper .react-datepicker__close-icon {
    pointer-events: none;
    opacity: 0.5;
}

.react-datepicker__close-icon::after {
    position: relative;
    top: 1px;
    content: '';
    width: 24px;
    height: 24px;
    background: transparent
        url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ3NTBfMjY3NDcwKSI+CjxwYXRoIGQ9Ik0xOC4wMzE4IDcuNDI0MzlDMTUuMTA0NCA0LjQ5Njk3IDEwLjM1MjYgNC40OTY5NyA3LjQyNTIxIDcuNDI0MzlDNC40OTc3OSAxMC4zNTE4IDQuNDk3NzkgMTUuMTAzNiA3LjQyNTIxIDE4LjAzMUMxMC4zNTI2IDIwLjk1ODQgMTUuMTA0NCAyMC45NTg0IDE4LjAzMTggMTguMDMxQzIwLjk1OTIgMTUuMTAzNiAyMC45NTkyIDEwLjM1MTggMTguMDMxOCA3LjQyNDM5Wk0xNC4zMTk1IDE1LjM3OTNMMTIuNzI4NSAxMy43ODg0TDExLjEzNzUgMTUuMzc5M0MxMC44NDU4IDE1LjY3MSAxMC4zNjg1IDE1LjY3MSAxMC4wNzY5IDE1LjM3OTNDOS43ODUxOCAxNS4wODc3IDkuNzg1MTggMTQuNjEwNCAxMC4wNzY5IDE0LjMxODdMMTEuNjY3OSAxMi43Mjc3TDEwLjA3NjkgMTEuMTM2N0M5Ljc4NTE4IDEwLjg0NSA5Ljc4NTE4IDEwLjM2NzcgMTAuMDc2OSAxMC4wNzZDMTAuMzY4NSA5Ljc4NDM2IDEwLjg0NTggOS43ODQzNiAxMS4xMzc1IDEwLjA3NkwxMi43Mjg1IDExLjY2N0wxNC4zMTk1IDEwLjA3NkMxNC42MTEyIDkuNzg0MzYgMTUuMDg4NSA5Ljc4NDM2IDE1LjM4MDIgMTAuMDc2QzE1LjY3MTggMTAuMzY3NyAxNS42NzE4IDEwLjg0NSAxNS4zODAyIDExLjEzNjdMMTMuNzg5MiAxMi43Mjc3TDE1LjM4MDIgMTQuMzE4N0MxNS42NzE4IDE0LjYxMDQgMTUuNjcxOCAxNS4wODc3IDE1LjM4MDIgMTUuMzc5M0MxNS4wODg1IDE1LjY3MSAxNC42MTEyIDE1LjY3MSAxNC4zMTk1IDE1LjM3OTNaIiBmaWxsPSIjNjQ2NDcxIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfNDc1MF8yNjc0NzAiPgo8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMi43Mjg1KSByb3RhdGUoNDUpIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==')
        no-repeat center center;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    padding: 0 10px 10px;
}

.react-datepicker__month {
    margin: 10px;
}

.react-datepicker__month-text {
    font-size: 15px;
    color: var(--chakra-colors-text-primary);
}

.react-datepicker__navigation-icon::before {
    border-color: var(--chakra-colors-gray-600);
}

/* React Datepicker - Year Picker */
.react-datepicker__year {
    margin-bottom: 0;
    padding-bottom: 0.4rem;
}

.react-year-picker-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 18px;
}

.react-year-picker-wrapper .react-datepicker-wrapper {
    width: 50px;
}

.react-year-picker-wrapper .react-datepicker-wrapper input {
    width: inherit;
    padding: 5px;
    border-radius: var(--chakra-radii-md);
    text-align: center;
}

.react-year-picker-wrapper .react-datepicker-wrapper input:focus-visible {
    outline: 1px solid var(--chakra-colors-blue-600);
}

.react-year-picker-wrapper .react-datepicker-popper {
    border: 1px solid var(--chakra-colors-datepicker-yearpicker-border);
    border-radius: var(--chakra-radii-md);
    box-shadow: 0px 4px 10px rgba(26, 26, 26, 0.2);
    padding-top: 0;
}

.react-year-picker-wrapper .react-datepicker__tab-loop .react-datepicker__navigation--previous,
.react-datepicker__header .react-datepicker__tab-loop .react-datepicker__navigation--next {
    top: 5px;
}

.react-year-picker-wrapper .react-datepicker__tab-loop .react-datepicker__header {
    padding-top: 11px;
}

.react-datepicker__year--container {
    background-color: var(--chakra-colors-background-tertiary);
}

.react-datepicker__year-wrapper {
    width: 170px;
}

.react-datepicker__year-text {
    width: 5rem !important;
    font-size: 16px;
    color: var(--chakra-colors-datepicker-text);
}

.react-datepicker__year-text:focus-visible {
    outline: none;
}

.react-datepicker__year-text--disabled,
.react-datepicker__day--disabled {
    color: var(--chakra-colors-datepicker-text-disabled);
    border-radius: var(--chakra-radii-md);
}

.react-datepicker__year-text--disabled:hover,
.react-datepicker__day--disabled:hover {
    background-color: unset;
}

.react-datepicker-year-header {
    font-size: 16px;
    color: var(--chakra-colors-input-text);
}

.tree-node-target {
    position: relative;
    display: inline-block;
}

.tree-node-tooltip {
    visibility: hidden;
    background-color: var(--chakra-colors-background-primary);
    color: var(--chakra-colors-input-text);
    text-align: center;
    border-radius: 6px;
    padding: 4px;
    position: fixed;
    z-index: 10;
    top: 80px;
    left: 24px;
}

.tree-node-target:hover .tree-node-tooltip {
    visibility: visible;
}

@media (max-width: 769px) {
    .tree-node-tooltip {
        display: none;
    }
}
